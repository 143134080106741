import React, { useState } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';

export const TermConditions = () => {
    const [validated, setValidated] = useState(false)
    return (
        <div className="site-main">
            <Header />

            {/* PageHeader */} 
            <PageHeader           
                title="Term & Conditions" 
                breadcrumb="Term & Conditions" 
            />
            {/* PageHeader end */}

            {/* map-section */} 
            <section className="ttm-row map-section bg-theme-GreyColor clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section title */}
                            <div className="section-title title-style-center_text pb-15">
                                <div className="title-header">
                                    <h3>Term <span className="text-theme-SkinColor"> Conditions</span></h3>
                                </div>
                            </div>{/* section title end */} 
                        </div>
                        <div className="col-lg-6">
                            <h4>Term & Conditions here</h4>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>
            {/* map-section end */} 

            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment-5"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Incredible Recruitment & Staffing Agency</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>We have over 11 years experience oft Business.</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                href={process.env.PUBLIC_URL + '/contact_02'}>Hiring Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}

            <Footer/>

        </div>
    )
    
}


export default TermConditions;