import React, { useState, useEffect } from 'react';
import SideNavbar from '../components/layout/SideNavbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {BottomFooter} from '../components/layout/BottomFooter';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useHistory } from 'react-router-dom';


export const MyCart = () => {
    const [cartList,setCartList] = useState([])
    const history = useHistory()
    const auth=sessionStorage.getItem('user')
    var access_token='';
    const api_url=process.env.REACT_APP_API_URL
    useEffect( () => {
        if(auth){
            getCart();
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    const getCart = async () => {
        const api_url=process.env.REACT_APP_API_URL
        access_token=sessionStorage.getItem('user')
        access_token="Bearer "+access_token;
        const result=fetch(api_url + 'user/get_cart', {
            method: 'GET',
            headers: {
                "Authorization": access_token,
            }
        })
        .then(response=>response.json())
        .then(result=> {
            if(result.status === true){
                setCartList(result.cart);
            }else{
                alert(result.error+"!!")
                history.push(process.env.PUBLIC_URL+'/');
            }
        })
    }

    const removeItem = async(id) => {
        const api_url=process.env.REACT_APP_API_URL
        access_token=sessionStorage.getItem('user')
        access_token="Bearer "+access_token;
        let raw = JSON.stringify({"cart_id":id});
        const result=fetch(api_url + 'user/delete_cart', {
            method: 'POST',
            headers: {
                "Authorization": access_token,
                "Content-Type": "application/json",
            },
            body: raw,
        })
        .then(response=>response.json())
        .then(result=> {
            if(result.status === true){
                alert('Item Removed Successfully!!');
            }else{
                alert(result.error+"!!")
                history.push(process.env.PUBLIC_URL+'/');
            }
        })
    }
    return (
        <>
            <div>
                <SideNavbar />
                <div style={{ display: 'flex' }}>
                    <Container>
                        <div className='card mb-4'>
                            <div className='card-header'>
                                <Typography variant="h6">My Cart ({cartList.length})</Typography>
                            </div>
                            {
                            cartList && cartList.length> 0 ?
                            cartList.map((item, index) => 
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <a href={`${process.env.PUBLIC_URL + '/member_profile/'+item.to_user_id._id}`}>
                                            <div className='card mb-2'>
                                                <div className='row'>
                                                    <div className='col-2 m-2'>
                                                        <img src={process.env.PUBLIC_URL+"/"+item.to_user_id.profile} height="40" width="40" rounded />
                                                    </div>
                                                    <div className='col-7 m-2'>
                                                        <h4>{item.to_user_id.name}</h4>
                                                    </div>
                                                    <div className='col-2 m-2'>
                                                        <a title='Remove Cart Item' onClick={removeItem(item._id)}><ShoppingCartIcon className='text-danger mt-1' /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            ):''}
                        </div>
                    </Container>
                </div>
                <BottomFooter />
            </div>
        </>
    )
}


export default MyCart;