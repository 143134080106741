import React, { Component } from 'react';
import Slider from 'react-slick';
import ProgressBar from 'react-animated-progress-bar';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';
import Video from '../components/layout/Video';


export class About_us extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
    render() {
      var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,

        responsive: [ {

            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },{
    
          breakpoint: 778,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      },
      {
          breakpoint: 575,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }]
      };
        
        return (       
          <div className="site-main">

            <Header/>
            
            {/* PageHeader */} 
            <PageHeader
                title="about us"
                breadcrumb="about"
            />
            {/* PageHeader end */} 

            
            {/* about-section */}
            <section className="ttm-row about-section clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-6">
                            {/* section title */}
                            <div className="section-title">
                                <div className="title-header">
                                    <h3>Great <span className="text-theme-SkinColor">Search! </span></h3>
                                    <h2 className="title">Help Organizations To Match Talent to Opportunity</h2>
                                </div>
                                <div className="title-desc">
                                    <p>We give job searchers and IT experts the chance to track down ideal a profession that guarantees long term development and learning.</p>
                                </div>
                            </div>{/* section title end */}
                            <div className="ttm-tabs ttm-tab-style-01">
                                <Tabs>
                                    <TabList className="tabs">
                                        <Tab className="tab"><a className="tab-1" tabIndex="0">Our Mision</a></Tab>
                                        <Tab className="tab"><a className="tab-2" tabIndex="0">Our Vision</a></Tab>
                                        <Tab className="tab"><a className="tab-3" tabIndex="0">Core Values</a></Tab>
                                    </TabList> 
                                    <div className="content-tab">                                
                                        <TabPanel>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-3 col-sm-4">
                                                <img className="img-fluid alignleft" height="200" width="200" src={process.env.PUBLIC_URL+'/images/mission.jpg'} alt="image" />
                                            </div>
                                            <div className="col-lg-8 col-md-9 col-sm-8">
                                                <div className="pt-15">
                                                    <p>We adapt to the our clients changing needs as well changes into the market to make sure growth of
                                                     the business, success and happiness</p>
                                                    <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">An ability to attract and retain great talent</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">A talent need, quickly and cost-effectively</div></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        </TabPanel>
                                        <TabPanel>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-3 col-sm-4">
                                                <img className="img-fluid alignleft" height="200" width="200" src={process.env.PUBLIC_URL+'/images/our-vision.jpg'} alt="image" />
                                            </div>
                                            <div className="col-lg-8 col-md-9 col-sm-8">
                                                <div className="pt-15">
                                                    <p>We adapt to the our clients changing needs as well changes into the market to make sure growth of
                                                     the business, success and happiness</p>
                                                    <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">An ability to attract and retain great talent</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">A talent need, quickly and cost-effectively</div></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        </TabPanel>
                                        <TabPanel>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-3 col-sm-4">
                                                <img className="img-fluid alignleft" height="200" width="200" src={process.env.PUBLIC_URL+'/images/our_values1.jpg'} alt="image" />
                                            </div>
                                            <div className="col-lg-8 col-md-9 col-sm-8">
                                                <div className="pt-15">
                                                    <p>We adapt to the our clients changing needs as well changes into the market to make sure growth of
                                                     the business, success and happiness</p>
                                                    <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">An ability to attract and retain great talent</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">A talent need, quickly and cost-effectively</div></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        </TabPanel>
                                    </div>       
                                </Tabs>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mr-30 mt-30 mt-lg-60 mb-lg-30">
                                <div className="d-flex">
                                    <img src={process.env.PUBLIC_URL+'/images/228fa.png'} className="img-fluid" alt="single_05" />
                                    <div className="flex-basis-auto ml_180 mr_30 mt_30 z-index_1">
                                        <img src={process.env.PUBLIC_URL+'/images/banner-img-8278c.png'} className="img-fluid" alt="dot-pattern" />
                                    </div>
                                    <div className="d-flex justify-content-end flex-column ml_180 mr_30 mb_35 z-index_1">
                                        <img src={process.env.PUBLIC_URL+'/images/banner-img-8278c.png'} className="img-fluid" alt="dot-pattern" />
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>{/* row end */}
                </div>
            </section>
            {/* about-section end */}


            {/* team-section */}
            <section className="ttm-row team-section bg-theme-GreyColor clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section title */}
                            <div className="section-title title-style-center_text">
                                <div className="title-header">
                                    <h3>Experts <span className="text-theme-SkinColor">team!</span></h3>
                                    <h2 className="title">Our Talented Recruiters</h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                    </div>{/* row end */}
                    {/* Slider */}
                    <Slider className="row slick_slider mb_10" {...slick_slider} slidesToShow={4} arrows={false} autoplay={false}>
                        <div className="col-md-12">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-team style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL+'/images/image-116f8.png'} />
                                </div>{/* featured-thumbnail end */}
                                <div className="featured-media ttm-media-link">
                                    <div className="media-block">
                                        <ul className="social-icons list-inline">
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="facebook"><i className="ti ti-facebook"></i></a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="twitter"><i className="ti ti-twitter-alt"></i></a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="google"><i className="ti ti-google"></i></a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="linkedin"><i className="ti ti-linkedin"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3><a href={process.env.PUBLIC_URL + '/'}>XYZ</a></h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Engineer</p>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox end */}
                        </div>
                        <div className="col-md-12">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-team style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                <img className="img-fluid" src={process.env.PUBLIC_URL+'/images/image-116f8.png'} />
                                </div>{/* featured-thumbnail end */}
                                <div className="featured-media ttm-media-link">
                                    <div className="media-block">
                                        <ul className="social-icons list-inline">
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="facebook"><i className="ti ti-facebook"></i></a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="twitter"><i className="ti ti-twitter-alt"></i></a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="google"><i className="ti ti-google"></i></a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="linkedin"><i className="ti ti-linkedin"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3><a href={process.env.PUBLIC_URL + '/'}>ABC</a></h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Engineer</p>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox end */}
                        </div>
                        <div className="col-md-12">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-team style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                <img className="img-fluid" src={process.env.PUBLIC_URL+'/images/image-116f8.png'} />
                                </div>{/* featured-thumbnail end */}
                                <div className="featured-media ttm-media-link">
                                    <div className="media-block">
                                        <ul className="social-icons list-inline">
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="facebook"><i className="ti ti-facebook"></i></a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="twitter"><i className="ti ti-twitter-alt"></i></a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="google"><i className="ti ti-google"></i></a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="linkedin"><i className="ti ti-linkedin"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3><a href={process.env.PUBLIC_URL + '/'}>MLM</a></h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Engineer</p>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox end */}
                        </div>
                        <div className="col-md-12">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-team style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                <img className="img-fluid" src={process.env.PUBLIC_URL+'/images/image-116f8.png'} />
                                </div>{/* featured-thumbnail end */}
                                <div className="featured-media ttm-media-link">
                                    <div className="media-block">
                                        <ul className="social-icons list-inline">
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="facebook"><i className="ti ti-facebook"></i></a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="twitter"><i className="ti ti-twitter-alt"></i></a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="google"><i className="ti ti-google"></i></a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="linkedin"><i className="ti ti-linkedin"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3><a href={process.env.PUBLIC_URL + '/'}>Nana</a></h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Engineer</p>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox end */}
                        </div>
                        <div className="col-md-12">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-team style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                <img className="img-fluid" src={process.env.PUBLIC_URL+'/images/image-116f8.png'} />
                                </div>{/* featured-thumbnail end */}
                                <div className="featured-media ttm-media-link">
                                    <div className="media-block">
                                        <ul className="social-icons list-inline">
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="facebook"><i className="ti ti-facebook"></i></a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="twitter"><i className="ti ti-twitter-alt"></i></a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="google"><i className="ti ti-google"></i></a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/'} rel="noopener" aria-label="linkedin"><i className="ti ti-linkedin"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3><a href={process.env.PUBLIC_URL + '/'}>IT</a></h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Engineer</p>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox end */}
                        </div>
                    </Slider>
                    {/* Slider end */}
                </div>
            </section>
            {/* team-section end */}


            {/* job-list-section */}
            <section className="ttm-row job-list-section ttm-bg clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section title */}
                            <div className="section-title title-style-center_text">
                                <div className="title-header">
                                    <h3>our <span className="text-theme-SkinColor">Story!</span></h3>
                                    <h2 className="title">Know more about</h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                    </div>{/* row end */}
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="timeline-story" className="timeline-story-wrapper">
                                {/* timeline */}
                                <div className="timeline">
                                    <div className="timeline-panel">
                                        <div className="timeline-shape">
                                            <span className="shape-circle"></span>
                                            <span className="shape-image"><img className="img-fluid" src="images/dotted_shape.png" /></span>
                                        </div>
                                        <div className="timeline-body">
                                            <div className="timeline-date">DEC 15 2013</div>
                                            <h3 className="title">An Establishment</h3>
                                            <p>We have started from the base in 1991 with the enthusiastic nature as start-up firm...</p>
                                        </div>
                                    </div>
                                    <div className="timeline-panel">
                                        <div className="timeline-shape">
                                            <span className="shape-circle"></span>
                                            <span className="shape-image"><img className="img-fluid" src="images/dotted_shape.png" /></span>
                                        </div>
                                        <div className="timeline-body">
                                            <div className="timeline-date">DEC 15 2013</div>
                                            <h3 className="title">Team Collaboration</h3>
                                            <p>Recruitment process doesn’t hinge on just one person, it requires numerous people</p>
                                        </div>
                                    </div>
                                    <div className="timeline-panel">
                                        <div className="timeline-shape">
                                            <span className="shape-circle"></span>
                                            <span className="shape-image"><img className="img-fluid" src="images/dotted_shape.png" /></span>
                                        </div>
                                        <div className="timeline-body">
                                            <div className="timeline-date">DEC 15 2013</div>
                                            <h3 className="title">An Outsourcing Firm</h3>
                                            <p>Global firm was grappling with inability to hire experienced executives great culture</p>
                                        </div>
                                    </div>
                                    <div className="timeline-panel">
                                        <div className="timeline-shape">
                                            <span className="shape-circle"></span>
                                            <span className="shape-image"><img className="img-fluid" src="images/dotted_shape.png" /></span>
                                        </div>
                                        <div className="timeline-body">
                                            <div className="timeline-date">DEC 15 2013</div>
                                            <h3 className="title">The Leading Group</h3>
                                            <p>Become leading the unique forum of exchange experiences innovative solutions...</p>
                                        </div>
                                    </div>
                                    <div className="timeline-panel">
                                        <div className="timeline-shape">
                                            <span className="shape-circle"></span>
                                            <span className="shape-image"><img className="img-fluid" src="images/dotted_shape.png" /></span>
                                        </div>
                                        <div className="timeline-body">
                                            <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey ttm-icon-btn-right" 
                                            href={process.env.PUBLIC_URL + '/'}>Read More<i className="ti ti-angle-double-right"></i></a>
                                        </div>
                                    </div>
                                </div>{/* timeline end */}
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>
            {/* job-list end */}


            {/* google_map */}
            <div id="google_map" className="google_map">
                <div className="map_container clearfix">
                    <div id="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117223.77996815203!2d85.32132625000001!3d23.343204800000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f4e104aa5db7dd%3A0xdc09d49d6899f43e!2sRanchi%2C%20Jharkhand!5e0!3m2!1sen!2sin!4v1702473520403!5m2!1sen!2sin" width="100%" height="530"></iframe>
                    </div>
                </div>
            </div>
            {/* google_map end */}
            

            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment-5"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Incredible Recruitment & Staffing Agency</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>We have over 11 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                href={process.env.PUBLIC_URL + '/'}>Hiring Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}


            <Footer/>
                        
         </div>
       )
   }
}


export default About_us;