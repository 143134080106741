import React, { useEffect,useState } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { PaginationItem, PaginationLink,} from 'react'
import "../css/custom.css";


const Employees_list = () => {
    const [employerList,setEmployerList] = useState([]);
    const api_url=process.env.REACT_APP_API_URL
    const auth=sessionStorage.getItem('user')
    useEffect(()=> {
        fetch(api_url+'/user/get_employee'
        )
        .then(response => response.json())
        .then(result => setEmployerList(result))
        .catch(error => console.log(error));
    },[]);

    const [activePage, setActivePage] = useState(1);
  const totalItems = employerList.employee ? employerList.employee.length : 0; // Total items in your list
  const itemsPerPage = 10; // Items per page

  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = employerList.employee ? employerList.employee.slice(indexOfFirstItem, indexOfLastItem):0;

  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const handlePageClick = (page) => {
    setActivePage(page);
    // You can perform actions here when a page is clicked, like fetching data for that page
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    for (let i = 1; i <= pageCount; i++) {
      paginationItems.push(
        <PaginationItem key={i} active={activePage === i}>
          <PaginationLink onClick={() => handlePageClick(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return paginationItems;
  };

    return (
        <>
            <div className="site-main">
                <Header/>
            
                {/* PageHeader */} 
                <PageHeader
                    title="employers list"
                    breadcrumb="employers"
                />
                {/* PageHeader end */}


                <div className="ttm-row sidebar job-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12 content-area">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="showing-result-block d-sm-flex align-items-center justify-content-center">
                                            <span className="showing-result">All Employees</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        employerList.employee && employerList.employee.length> 0 ?
                                        currentItems.map((item, index) => 
                                        <div className="col-lg-12 col-md-12">
                                            {/* featured-imagebox */}
                                            <div className="featured-imagebox featured-imagebox-employer">
                                                <div className="featured-thumbnail">
                                                    <img src={api_url+item.profile} />
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3>{item.name}</h3>
                                                    </div>
                                                    <div className="featured-bottom">
                                                        <div className="job-meta">
                                                            <span className='skills-list'>Skills: {item.userSkills.map((item2, index) => (
                                                                index === item.userSkills.length - 1 ? item2.name : item2.name + ", "
                                                                ))}
                                                            </span>
                                                            <span>Rating<br></br>
                                                                <div className="star-ratings">
                                                                    <ul className="rating">
                                                                        {[1, 2, 3, 4, 5].map((valuess) => (
                                                                        <li className={valuess <= item.avg_rating ? 'active':''}><i className={valuess <= item.avg_rating ? 'fa fa-star bg-yellow':'fa fa-star text-dark'}></i></li>
                                                                        ))}
                                                                        <li className='m-3 mt-0 text-dark'><span className='fa fa-thumbs-up'>{item.liked_by.length}</span></li>
                                                                    </ul>
                                                                </div>

                                                            </span>
                                                        </div>
                                                        {auth?(<>
                                                        <div className="view-block">
                                                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark" 
                                                            href={process.env.PUBLIC_URL + '/member_profile/'+item._id}>view Profile</a>
                                                        </div>
                                                        </>):''}
                                                    </div>
                                                </div>
                                            </div>{/* featured-imagebox end */}
                                        </div>
                                        )
                                        :''
                                    }
                                    
                                    <div className="col-lg-12">
                                        <div className="job-pagination-block">
                                            <a className="page-nav-link">prev</a>
                                            <a className="page-nav-link current">1</a>
                                            <a className="page-nav-link" href="#">2</a>
                                            <a className="page-nav-link" href="#">3</a>
                                            <a className="page-nav-link" href="#">....</a>
                                            <a className="page-nav-link">next</a>
                                        </div>
                                    </div>
                                </div>{/* row end */}
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>


                {/* action-section */}
                <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-md-flex align-items-center justify-content-between">
                                    {/* featured-icon-box */}
                                    <div className="featured-icon-box icon-align-before-content style2">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                                <i className="flaticon flaticon-recruitment-5"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Incredible Recruitment & Staffing Agency</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>We have over 11 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.</p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end */}
                                    <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" href='/contact-us'>Hiring Now!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* action-section end */}

                
            <Footer/>
                        
            </div>
        </>
    );
};

export default Employees_list;